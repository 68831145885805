import { createContext, useContext, useEffect, useState } from 'react';
import LoadingQueue from '@/_models/virtualStaging/LoadingQueue';

export enum LoadingSteps {
  data = 'Fetching Data', //10%
  tags = 'Loading Data', //30%
  showcase = 'Loading Showcase', //20%
  vs = 'Loading Virtual Assets', //rest
  ready = 'Ready',
}

export type TLoadingProgress = {
  loadingProgress: number | boolean;
  loadingStep: LoadingSteps;
};

export const LoadingProgressContext = createContext<TLoadingProgress>({
  loadingProgress: 0,
  loadingStep: LoadingSteps.data,
});

export const LoadingProgressProvider = ({ children }: { children: React.ReactNode }) => {
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [loadingStep, setLoadingStep] = useState<LoadingSteps>(LoadingSteps.data);
  const [loadingQueueProgress, setLoadingQueueProgress] = useState<number | boolean>(0);

  useEffect(() => {
    if(loadingQueueProgress === true) {
      setLoadingProgress(100);
      setLoadingStep(LoadingSteps.ready);
    }
    if (loadingQueueProgress > 0 && typeof loadingQueueProgress === "number" ) {
      if(loadingQueueProgress > loadingProgress) {
        setLoadingProgress(loadingQueueProgress);
      }
      if (loadingQueueProgress < 20 && loadingQueueProgress > 10) {
        setLoadingStep(LoadingSteps.data);
      } else {
        switch (loadingQueueProgress) {
          case 100:
            setLoadingStep(LoadingSteps.ready);
            break;
          case 20:
            setLoadingStep(LoadingSteps.showcase);
            break;
          case 30:
            setLoadingStep(LoadingSteps.tags);
            break;
          default:
            setLoadingStep(LoadingSteps.vs);
            break;
        }
      }
    }
  }, [loadingQueueProgress]);

  useEffect(() => {
    LoadingQueue.getInstance().subscribe(setLoadingQueueProgress);
  }, []);

  return <LoadingProgressContext.Provider value={{ loadingProgress, loadingStep }}>{children}</LoadingProgressContext.Provider>;
};

export const useLoadingProgress = () => useContext(LoadingProgressContext);
