import { Stack, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';
import { startGA } from '../util/analytic';
import { useCookieAcceptance } from '@/hooks/useCookies';

interface CustomCookieConsentProps {
  message: string;
  accept: string;
  decline: string;
  messageMandatoryTitle: string;
  messageMandatoryDescription: string;
  acceptMandatory: string;
  declineMandatory: string;
  tour: any;
  loaded: boolean;
  showFullScreen?: boolean;
  startScreenOn?: boolean;
  onAccept?: () => void;
}

export const treedisCookieName = 'allow_treedis_cookie';
export const allowUsesCookie = (hide = false) => {
  const cookie = getCookieConsentValue(treedisCookieName);
  if (cookie === undefined) {
    // no value set yet for cookie
    if (hide) {
      // message is not activated, assume we can track
      return true;
    }
    // message is activated, so wait for user response
    return false;
  }
  return cookie === 'true';
};

export function CustomCookieConsent({
  message,
  accept,
  decline,
  tour,
  messageMandatoryTitle,
  messageMandatoryDescription,
  acceptMandatory,
  declineMandatory,
  loaded,
  showFullScreen,
  startScreenOn,
  onAccept,
}: CustomCookieConsentProps) {
  const [step, setStep] = useState(1);
  const { updateCookieAcceptance } = useCookieAcceptance();

  const hideOnDecline = useMemo(() => {
    if (!tour.cookieIsMandatory) {
      return true;
    }
    return false;
  }, [tour.cookieIsMandatory]);

  const handleDecline = () => {
    if (step === 1 && tour.cookieIsMandatory) {
      setStep(2);
      return;
    }
    if (step === 2) {
      window.location.href = 'https://www.google.com/';
    }
  };

  return (
    <CookieConsent
      location="bottom"
      cookieName={treedisCookieName}
      style={{
        background: 'rgba(0, 0, 0, 0.67)',
        fontFamily: 'Roboto',
        fontSize: '22px',
        width: '100%',
        maxHeight: step === 2 || showFullScreen ? '100%' : '158px',
        height: '100%',
        backdropFilter: !startScreenOn ? 'blur(14.5px)' : 'blur(24.5px)',
        WebkitBackdropFilter: !startScreenOn ? 'blur(14.5px)' : 'blur(24.5px)',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: step === 2 || showFullScreen ? 'column' : 'row',
        padding: '10px 35px ',
      }}
      contentStyle={{
        flex: '0 1 auto',
        display: 'flex',
        flexDirection: step === 2 || showFullScreen ? 'column' : 'row',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '360px',
        textAlign: step === 2 || showFullScreen ? 'center' : 'left',
        fontSize: '16px',
        gap: '20px',
        lineHeight: '100%',
        margin: 0,
      }}
      buttonText={step === 1 ? accept || 'Accept' : acceptMandatory || 'Accept cookies to enter'}
      buttonClasses="cookie-button"
      buttonWrapperClasses={step === 1 ? 'cookie-buttons-container' : 'cookie-buttons-container-2'}
      enableDeclineButton
      declineButtonText={step === 1 ? decline || 'Decline' : declineMandatory || 'Leave this website'}
      declineButtonClasses="cookie-button"
      declineButtonStyle={{
        background: 'transparent',
        color: '#fff',
        border: '2.7697px solid rgba(255, 255, 255, 0.15)',
        display: step === 2 ? 'none' : undefined,
      }}
      disableButtonStyles
      onAccept={() => {
        if (onAccept) {
          onAccept();
        }
        updateCookieAcceptance();
        startGA(tour, loaded, true);
      }}
      onDecline={handleDecline}
      setDeclineCookie={false}
      // @ts-ignore
      hideOnDecline={hideOnDecline}
    >
      <Stack width="56px" height="56px" alignItems="center" justifyContent="center">
        <img src="/cookie.svg" alt="cookie" />
      </Stack>
      {step === 1 ? (
        <Typography variant="body1" sx={{ mb: showFullScreen ? 4 : 0 }}>
          {message || 'This website uses cookies to enhance the user experience.'}
        </Typography>
      ) : (
        <Stack>
          <Typography variant="subtitle1" sx={{ mb: 2 }}>
            {messageMandatoryTitle || 'Why It’s Important to Accept Cookies?'}
          </Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            {messageMandatoryDescription ||
              `Please note, if you decline cookies, we will not be able to display any of the features on this website. To fully use this website, please select ${(
                <strong>{acceptMandatory || 'Accept'}</strong>
              )}`}
          </Typography>
        </Stack>
      )}
    </CookieConsent>
  );
}
